import React from 'react'
import CarouselComponent from './CarouselComponent'
import { Link } from 'react-router-dom'

function Menu() {
  return (
    <div id='menu' className='pt-12 flex flex-col justify-center items-center  '>
      <div className='w-44  bg-[#116a47] rounded-full flex justify-center font-arregular items-center text-[#f4d7aa] text-[38px]  mb-12' >
        Menu

      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col gap-2 sm:gap-5 sm:flex-row justify-center w-full">
        <Link to={'/menu'} className='flex flex-col justify-center items-center gap-2 w-full sm:w-5/12 p-4'>
            <img src="/images/menusnack.png" alt="Snacks" className="w-full rounded-xl h-auto object-cover transform hover:scale-95 transition-transform duration-300" />
          </Link>
          <Link to={'/menu'} className='flex flex-col justify-center items-center gap-2 w-full sm:w-5/12 p-4 '>
            <img src="/images/menudrink2.jpg" alt="Coffee" className="w-full  rounded-xl h-auto object-cover  transform hover:scale-95 transition-transform duration-300 " />
          </Link>  </div>

      </div>
      <Link to={'/menu'} className='w-auto px-4 border-2  border-[#116a47] rounded-full flex justify-evenly font-arregular items-center text-[#116a47] text-[31px]  m-12 transform transition-transform duration-300 hover:scale-105' >
       Snack & Drinks

      </Link>
    </div>
  )
}

export default Menu