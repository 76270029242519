import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import blogPosts from './data/data';

function Blog() {
  const location = useLocation();

  return (
    <div id="blog" className="w-full flex flex-col justify-center items-center pt-28 pb-24">
      <div className="w-44 bg-[#116a47] rounded-full flex justify-center font-arregular items-center text-[#f4d7aa] text-[38px] mb-12">
        Blog
      </div>
      <div className="w-full flex flex-col sm:flex-row justify-center items-center pb-8 gap-4">
        {blogPosts.map(post => (
          <Link
            key={post.id}
            to={`/blog/${post.id}`}
            className="w-full sm:w-5/12 lg:w-3/12 p-3 sm:p-0 flex flex-col cursor-pointer"
          >
            <img src={post.imgSrc} className="w-full h-auto" alt={`Blog ${post.id}`} />
            <div className="text-center shadow-md rounded-lg p-2 h-[110px]">
              <h3 className="text-[21px] font-bold font-arregular p-1">{post.head}</h3>
            </div>
          </Link>
        ))}
      </div>
      {/* Conditionally render the View All button only on the home page */}
      {location.pathname === '/' && (
        <Link
          to="/blog"
          className="w-44 border-2 border-[#116a47] rounded-full flex justify-evenly font-arregular items-center text-[#116a47] text-[31px] mb-12 transform transition-transform duration-300 hover:scale-105"
        >
          View All
        </Link>
      )}
    </div>
  );
}

export default Blog;
