import React from 'react';
import { useParams } from 'react-router-dom';
import blogPosts from './data/data';  // Assuming your blogPosts are imported from a file

function BlogPost() {
  const { id } = useParams();  // Get the id from the URL
  const post = blogPosts.find(post => post.id === parseInt(id, 10));  // Find the post by id

  if (!post) {
    return <div className="text-center py-16 text-2xl font-semibold">Post not found</div>;
  }

  const { points = [], text2 = '' } = post;

  return (
    <div className="bg-white">
      {/* Banner Section */}
      <div className="relative w-full h-[50vh] overflow-hidden">
        <img
          src={post.banner}
          alt={post.head}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 flex justify-center items-center text-center text-white px-8 py-4">
          <h1 className="text-4xl font-semibold font-arsenal">{post.head}</h1>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="container mx-auto px-6 py-12">
        <div className="flex flex-col md:flex-row gap-12">
          {/* Content Text */}
          <div className="flex flex-col w-full md:w-2/3">
            <p className="text-lg text-gray-700 leading-relaxed mb-8 font-arregular">{post.text}</p>
            <div className="space-y-6">
              {points.length > 0 ? (
                points.map((point, index) => (
                  <div key={index} className="space-y-2">
                    <h3 className="text-xl font-semibold text-gray-800 font-arregular">{point.point || 'No title provided'}</h3>
                    <p className="text-gray-600 text-lg font-arregular">{point.head || 'No description provided'}</p>
                  </div>
                ))
              ) : (
                <p className="text-gray-600">No key benefits available for this post.</p>
              )}
            </div>
          </div>

          {/* Image Section */}
          <div className="w-full md:w-1/3">
            <img
              src={post.imgSrc}
              alt={post.head}
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
        </div>

        {/* Additional Text Section */}
        <div className="my-12 text-lg text-gray-700 leading-relaxed ">
          <p className='font-arregular'>{text2}</p>
        </div>
      </div>
    </div>
  );
}

export default BlogPost;
