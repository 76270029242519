import React from 'react';

function About() {
  return (
    <div
      id="about"
      className="relative bg-[#116a47] h-[50vh] md:h-screen" // Reduced height on mobile
    >
      {/* Background Image */}
      <img
        src="/images/abbg.png"
        className="absolute bottom-0 left-0 w-full"
        alt="Background"
      />

      {/* Content Container */}
      <div className="relative flex flex-col md:flex-row justify-center items-center w-full h-full">
        {/* Left Section */}
        <div className="w-full md:w-1/2 hidden md:flex  justify-center md:justify-start items-center relative">
          <div className="relative">
            <img
              src="/images/cup.png"
              className="w-6/12 lg:w-5/12 mt-[-90px] ml-12"
              alt="Cup"
            />
            <img
              src="/images/coffee.png"
              className="absolute w-3/4 lg:w-2/3 top-20 left-0"
              alt="Coffee"
            />
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full md:w-1/2 flex flex-col items-start md:items-start text-center md:text-left mx-2 md:mt-0 px-5 md:px-8">
          <h1 className="text-[28px] md:text-[48px] font-medium font-arsenal text-[#ccb994]">
            About Us
          </h1>
          <p className="text-[14px] md:text-[21px] font-arregular text-[#ccb994] mt-4">
            We believe in serving with passion and love. It’s not just about enhancing the taste, it’s about strengthening the bond between people. Every cup we craft reflects our dedication to quality and connection, making your coffee experience truly special. From the carefully selected beans to the final sip, we pour our hearts into every detail, ensuring that whether you're sharing moments with friends or enjoying time for yourself, each visit is memorable.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
