import React, { useState, useRef, useEffect } from 'react';
import { FaPlayCircle, FaArrowLeft } from "react-icons/fa";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(TextPlugin);

function Home() {
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);
  const headingRefs = [useRef(null), useRef(null), useRef(null)]; // References for each slide heading

  const toggleVideo = () => {
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
  };

  const handleClickOutside = (event) => {
    if (videoRef.current && !videoRef.current.contains(event.target) && !event.target.closest('.video')) {
      closeVideo();
    }
  };

  useEffect(() => {
    if (showVideo) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showVideo]);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div id='home' className="relative w-full h-[100vh]">
      <Slider {...settings} className="w-full h-[100vh]">
        <div className="h-[100vh] flex items-center justify-center relative">
          <img src="/images/home1slide.webp" alt="Home Slide 1" className="object-cover w-full h-full" />
          <h1 className="absolute top-1/3 left-8 sm:left-16 text-5xl sm:text-7xl text-[#116a47] font-arsenal font-extrabold">Destination of <br/> Delight
          </h1>
        </div>
        <div className="h-[100vh] flex items-center justify-center relative">
          <img src="/images/slide2home.webp" alt="Home Slide 2" className="object-cover w-full h-full" />
          <h1  className="absolute top-1/3 left-8 sm:left-16 text-5xl sm:text-7xl text-[#116a47] font-arsenal font-extrabold">Finely Grinded<br/> Grains
          </h1> 
        </div>
        <div className="h-[100vh] flex items-center justify-center relative">
          <img src="/images/home2.webp" alt="Home Slide 3" className="object-cover w-full h-full" />
          <h1  className="absolute top-1/3 left-8 sm:left-16 text-5xl sm:text-7xl text-[#116a47] font-arsenal font-extrabold">Aromatic Fresh<br/> Brews
          </h1>
        </div>
      </Slider>

      {/* <div
        className='video w-44 p-2 bg-[#F4D7AA] left-8 sm:left-24 rounded-full flex justify-evenly items-center absolute top-1/3 mt-[200px] z-10 transform hover:scale-110 transition-transform duration-300'
        onClick={toggleVideo}
      >
        <FaPlayCircle className='text-[#116a47] text-4xl' />
        <p className='text-[#116a47] text-xl'> Play Video </p>
      </div> */}

      {showVideo && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-20">
          <button className="absolute top-4 left-4 z-30 text-white" onClick={closeVideo}>
            <FaArrowLeft className="text-2xl" />
          </button>
          <div ref={videoRef} className="relative w-full h-full bg-black">
            <video controls autoPlay className="w-full h-full">
              <source src='/images/jerry.mp4' type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
