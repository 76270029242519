import React from 'react';
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
// import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";

function Contact() {
  return (
    <div id="contact" className="relative w-full bg-[#FFFBF5] ">
      <img src="/images/contactbg.png" className="h-[40vh] md:h-[35vh] lg:h-[40vh] md:pt-0 w-full bg-transparent" alt="Home Background" />
      <div className="absolute inset-0 flex flex-col justify-end   items-end z-10 gap-20">
        <div className="absolute inset-x-0 top-0 flex flex-col md:flex-row justify-between items-center">
          <div className="w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center">
            <img className="w-2/12 ms-0 md:ms-12" src="/images/cafelogo.png" alt="Cafe Logo" />
            <div className="w-full md:w-8/12 flex flex-col gap-2 md:gap-6 pt-8">
              <a href="tel:+979785214756" target="_blank" className="flex gap-4 items-center" rel="noopener noreferrer">
                <FaPhone className="text-[#116a47] text-xl" />
                <span className="text-black text-sm">+97 9785214756</span>
              </a>
              <a href="mailto:info@aiincafe.com"  target="_blank" className="flex gap-4 items-center" rel="noopener noreferrer">
                <FaEnvelope className="text-[#116a47] text-xl" />
                <span className="text-black text-sm">info@aiincafe.com</span>
              </a>
              <div className="flex gap-4 items-center">
                <FaLocationDot className="text-[#116a47] text-xl" />
                <span className="text-black text-sm w-full md:w-1/2">
                  NH Bypass, Ponnurunni East, Chalikkavattom, Vyttila, Kochi, Ernakulam, Kerala - 682019
                </span>
              </div>
            </div>
          </div>
          <div className="hidden md:block md:w-3/12 flex justify-center items-start">
            <img src="/images/contactcup.png" className="w-11/12 mt-[-100px] " alt="Contact Cup" />
          </div>
        </div>
        {/* <div className="absolute inset-x-0 w-full flex flex-col md:flex-row justify-around items-center top-40">
          <div className="w-full md:w-7/12 flex flex-wrap justify-center md:justify-evenly  gap-x-6 md:gap-x-0">
            <a href="#home" className="text-sm">HOME</a>
            <a href="#about" className="text-sm">ABOUT</a>
            <a href="#menu" className="text-sm">MENU</a>
            <a href="#gallery" className="text-sm">GALLERY</a>
            <a href="#blog" className="text-sm">BLOG</a>
            <a href="#contact" className="text-sm">CONTACT</a>
          </div>
          <div className="w-/12 md:w-3/12 flex justify-evenly items-center">
            <p>Follow us</p>
            <a href="#" className="flex justify-center items-center py-7 px-2">
              <FaFacebook className="text-[#116a47] text-2xl" />
            </a>
            <a href="#" className="flex justify-center items-center py-7 px-2">
              <FaInstagram className="text-[#116a47] text-2xl" />
            </a>
            <a href="#" className="flex justify-center items-center py-7 px-2">
              <FaWhatsapp className="text-[#116a47] text-2xl" />
            </a>
          </div> 

//         </div>*/}
<div className="flex w-full justify-center">
  <div className="w-11/12 mt-[220px] border-t-2 border-[#116a47] py-4 text-sm flex justify-center items-center flex-col md:flex-row">
    <span className="hidden sm:inline">
      © 2024 All rights reserved By AIIN cafe Powered By
    </span>
    <span className="inline sm:hidden">
      © 2024 All rights reserved By AIIN cafe
    </span>
    <a
      href="https://codepublicist.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="hover:text-[#000] hover:font-bold"
    >
      <span className="block md:ml-1">Codepublicist</span>
    </a>
  </div>
</div>


     </div>
    </div>
  );
}

export default Contact;
