import React from 'react'
import Home from '../components/Home'
import About from '../components/About'
import Menu from '../components/Menu'
import ImageGallery from '../components/ImageGallery'
import Blog from '../components/Blog'
import Contact from '../components/Contact'
function HomePage() {
  return (
    <div>
        <Home/>
        <About/>
        <Menu/>
        <ImageGallery/>
        <Blog/>
        {/* <Contact/> */}
    </div>
  )
}

export default HomePage