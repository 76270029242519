import React, { useState, useMemo } from 'react';

const MenuItems = () => {
    const products = [
        {
            name: 'Samosas',
            imageurl: '/images/samosa.png',
            items: [
                "Chicken Samosa (Small)", 
                "(Pack of 3)", 
                "Chicken Samosa (Big)", 
                "Veg Samosa"
            ],
            category: 'snacks'
        },
        {
            name: 'Hot Beverages',
            imageurl: '/images/hots.png',
            items: [
                "Tea", 
                "Coffee", 
                "Hot chocolates"
            ],
            category: 'drinks'
        },
        {
            name: 'Cutlets',
            imageurl: '/images/cutlet.png',
            category: 'snacks',
            items: [
                "Chicken Cutlet ",
"Beef Cutlet"
            ]
        },
        {
            name: 'Juices',
            imageurl: '/images/juices.png',
            items: [
                "Fruit Juices", 
                "Vegetable Juices", 
                "Citrus Juice",
                "Slushies"
            ],
            category: 'drinks'
        },
        {
            name: 'Spring Rolls',
            imageurl: '/images/rolls.png',
            items: [
                "Chicken Spring Roll (Big) ",
            " Chicken Spring Roll (Small)",
               " Veg Spring Roll (Big)"
            ],
            category: 'snacks'
        },
        {
            name: 'Shakes',
            imageurl: '/images/shake.png',
            items: [
                "Milkshakes", 
                "Protein Shakes", 
                "Fruit Shakes",
                "Specialty Shakes",
                "Dessert Shakes"
            ],
            category: 'drinks'
        },
        {
            name: 'Momos',
            imageurl: '/images/momos.png',
            category: 'snacks',
            items: [
                "Chicken Momos (Small)", 
                "(Pack of 3)", 
                "Chicken Momos (Big)", 
                "Veg Momos"
            ]
        }
    ];

    const [activeCategory, setActiveCategory] = useState('All');
    const [hoverIndex, setHoverIndex] = useState(-1);  // To control hover state of elements


    const handleButtonClick = (category) => {
        setActiveCategory(category);
    };

    const filteredProducts = useMemo(() => {
        if (activeCategory === 'All') {
            return products;
        } else {
            return products.filter(product => product.category === activeCategory);
        }
    }, [activeCategory]);

    return (
        <div className='bg-[#dbc5a5] min-h-screen pb-[40px]'>
            <div className='flex flex-col justify-center items-center py-12 pt-32'>
                <ul className='flex justify-around items-center flex-wrap gap-8 '>
                    <li className={`p-2 text-sm sm:text-lg text-white ${activeCategory === 'All' ? 'border-b-2 border-[#116A47]' : ''}`}>
                        <button type='button' onClick={() => handleButtonClick('All')}>
                            <img className='w-[50px] h-[50px]' src='/images/alls.png' alt='All Products' />
                        </button>
                    </li>
                    <li className={`p-2 text-sm sm:text-lg text-white ${activeCategory === 'drinks' ? 'border-b-2 border-[#116A47]' : ''}`}>
                        <button type='button' onClick={() => handleButtonClick('drinks')}>
                            <img className='w-[50px] h-[50px]' src='/images/drinks.png' alt='Drinks' />
                        </button>
                    </li>
                    <li className={`p-2 text-sm sm:text-lg text-white ${activeCategory === 'snacks' ? 'border-b-2 border-[#116A49]' : ''}`}>
                        <button type='button' onClick={() => handleButtonClick('snacks')}>
                            <img className='w-[50px] h-[50px]' src='/images/snacks.png' alt='Snacks' />
                        </button>
                    </li>
                </ul>
                <div className='flex flex-wrap justify-center gap-4 items-end w-full'>
                    {filteredProducts.map((item, index) => (
                       <div 
                       key={index} 
                       className='flex flex-col w-10/12 md:w-1/5 transition-transform duration-300 group '
                       onMouseEnter={() => setHoverIndex(index)}
                       onMouseLeave={() => setHoverIndex(-1)}
                       style={{
                        transition: 'transform 10.5s ease 10.5s, background-color 10.5s ease 10.5s, color 1.5s ease 1.5s'
                    }}
                   >                            <img
                                src={item.imageurl}
                                alt={item.name}
                                className={`w-1/2 md:w-full object-cover rounded-lg mb-[-80px] z-20 relative ${hoverIndex === index ? 'scale-110 ' : ''}`}
                            />
                  
                  <div className={`max-w-sm bg-gray-200  rounded-xl z-10 flex flex-col text-gray-700 group-hover:text-white group-hover:bg-[#116A47] justify-start pt-[80px] items-start pl-4 lg:pl-0 lg:items-center shadow-md overflow-hidden min-h-[280px] hover:bg-[#116A47] md:min-h-[350px] relative ${hoverIndex === index ? 'scale-y-110  text-white bg-[#116A47]' : ''}`}>
                  <div className=" text-left">
                                        <h2 className="text-2xl text-left  mb-2">{item.name}</h2>
                                        <div className="text-sm text-left">
                                            {item.items.map((subItem, subIndex) => (
                                                <p className='py-1' key={subIndex}>{subItem}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                         </div>
                        
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MenuItems;
